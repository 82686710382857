.p-password {
    display: block !important; /* Or another display property as per your design */
}

.p-card .p-card-content {
    padding: 0 0;
}

.p-card .p-card-title {
    font-size: 0;
    font-weight: 0;
    margin-bottom: 0;
}
